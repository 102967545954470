import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import Member from '../../../core/models/member.model';
import { DataService } from '../../../core/services/data.service';
import { API_TOKEN_CEVEO } from '../../../app.config';
import { StorageService } from '../../../core/services/storage.service';

const LOCAL_STORAGE_VARIABLE = 'dataToken';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  header : any ;

  constructor(private data:DataService,private storageService : StorageService) {
    this.header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${API_TOKEN_CEVEO}`
    });
  }

  register(member: Member, userParentId: string = ''): Observable<Member> {
    let url = `members`;
    const data = {
      data: { ...member } // Spread the properties of newMember inside 'data'
    };
    return this.data.postOneWithSpecificHeader(url, data, this.header);
  }

  login(memberLogin: MemberLogin): Observable<any> {
    return this.data.postOne(`auth/login`, memberLogin).pipe(
      map(async dataToken => {
        this.storageService.setItem('dataToken', JSON.stringify(dataToken));
        this.setupRole();
        return dataToken;
      })
    );
  }

  setupRole() : void {
    const role = JSON.parse(this.storageService.getItem('dataToken') ?? '')?.role;
    this.storageService.setItem('role' , role == 'candidate' ? 'C' : 'R');
  }

  //post the personall info
  postPersonalInfo(personalInfo: any) {
    return this.data.postOne(`members/personal-info`, personalInfo);
  }

  logOut() {
    localStorage.removeItem(LOCAL_STORAGE_VARIABLE);
    localStorage.removeItem('role');
  }

  isLoggedIn() {
    const authToken = localStorage.getItem('dataToken');
    return !!authToken;
  }

  resetPassword(emailUrl: any) {
    return this.data.postOne(`reset-password`, emailUrl);
  }

  setNewPassWord(token: string, password: any) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    });
    console.log('header ==== ', header);
    return this.data.postOneWithSpecificHeader(
      `/members/set-new-password`,
      password,
      header
    );
  }

  getRefreshToken(): string {
    const dataToken = localStorage.getItem('dataToken');
    if (dataToken) {
      const parseAuthToken = JSON.parse(dataToken);
      return parseAuthToken.refreshToken;
    }
    return '';
  }

  getAccessToken(): string {
    const authToken = localStorage.getItem('dataToken');
    if (authToken) {
      const parseAuthToken = JSON.parse(authToken);
      return parseAuthToken.access_token;
    }
    return '';
  }

  refreshToken(): Observable<any> {
    return this.data.postOneWithoutAuthorization('auth/refresh-token', {
      "refreshToken" : this.getRefreshToken()
    });
  }

  setAuthData(dataToken: any) {
    localStorage.setItem(LOCAL_STORAGE_VARIABLE, JSON.stringify(dataToken));
  }


}

export interface MemberLogin {
  email : string ,
  password : string
}
