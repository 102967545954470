import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { API_TOKEN_CEVEO } from '../../app.config';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public apiUrl!: string;
  public isTokenExpired!: boolean;

  constructor(
    private http: HttpClient,
  ) {
    this.apiUrl = environment.baseUrl;
  }

  getOptions(): {} {
    let dataToken: any = localStorage.getItem('dataToken');
    if (dataToken) {
      dataToken = JSON.parse(dataToken);
      const header = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${API_TOKEN_CEVEO}`,
        AccessToken: `${dataToken.accessToken}`,
      });
      return { headers: header };
    }
    return { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  }

  getAll<T>(path: string, params?: any): Observable<T> {
    let paramsToSend = ``;
    let args: any;
    if (params) {
      args = JSON.stringify(params);
      paramsToSend = `?args=${args}`;
    }
    return this.http
      .get<T>(`${this.apiUrl}/${path}${paramsToSend}`, this.getOptions())
      .pipe(
        catchError(error => {
          return this.handleError(error, path);
        })
      );
  }

  postMany<T>(path: string, data: any): Observable<T> {
    return this.http
      .post<T>(`${this.apiUrl}/${path}`, data, this.getOptions())
      .pipe(
        catchError(error => {
          return this.handleError(error, path);
        })
      );
  }

  postOne<T>(path: string, data: any): Observable<T> {
    return this.http
      .post<T>(`${this.apiUrl}/${path}`, data, this.getOptions())
      .pipe(
        catchError(error => {
          return this.handleError(error, path);
        })
      );
  }

  postOneWithoutAuthorization<T>(path: string, data: any): Observable<T> {
    return this.http
      .post<T>(`${this.apiUrl}/${path}`, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
      .pipe(
        catchError(error => {
          return this.handleError(error, path);
        })
      );
  }

  postOneWithSpecificHeader<T>(
    path: string,
    data: any,
    header: {}
  ): Observable<T> {
    return this.http
      .post<T>(`${this.apiUrl}/${path}`, data, {
        headers: header,
      })
      .pipe(
        catchError(error => {
          return this.handleError(error, path);
        })
      );
  }

  getOneWithSpecificHeader<T>(path: string, header: {}): Observable<T> {
    return this.http
      .get<T>(`${this.apiUrl}/${path}`, {
        headers: header,
      })
      .pipe(
        catchError(error => {
          return this.handleError(error, path);
        })
      );
  }

  updateOne<T>(path: string, id: any, data: any): Observable<T> {
    return this.http
      .put<T>(`${this.apiUrl}/${path}/${id}`, data, this.getOptions())
      .pipe(
        catchError(error => {
          return this.handleError(error, path);
        })
      );
  }

  deleteOne<T>(path: string, id: Number): Observable<T> {
    return this.http
      .delete<T>(`${this.apiUrl}/${path}/${id}`, this.getOptions())
      .pipe(
        catchError(error => {
          return this.handleError(error, path);
        })
      );
  }

  isLoggedIn(): boolean {
    return (
      !!localStorage.getItem('userId') && !!localStorage.getItem('dataToken')
    );
  }

  private handleError(error: HttpErrorResponse, url: string) {
    return throwError('errorMessage');
  }
}
