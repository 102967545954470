import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { LoadingAnimationService } from '../services/loading-animation.service';
import { AuthService } from '../../features/front-office/services/auth.service';
import { API_TOKEN_CEVEO } from '../../app.config';

// Define the interceptor as a function
export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const loading = inject(LoadingAnimationService);
  const authService = inject(AuthService);
  let totalRequests = 0;
  let refreshTokenInProgress: boolean = false;


  loading.showLoading(true) ;
  // Handle the request and catch errors
  return next(req).pipe(
    finalize(() => {
      totalRequests--;
      if (totalRequests <= 0) {
          loading.showLoading(false);
      }
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401 && authService.isLoggedIn()) {
        if (!refreshTokenInProgress) {
          // Set it to true
          refreshTokenInProgress = true;
          return authService.refreshToken().pipe(
            switchMap((dataToken: any) => {
              // Store data token in localStorage
              authService.setAuthData(dataToken);
              const newHeaders = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${API_TOKEN_CEVEO}`,
                AccessToken: `${dataToken.accessToken}`,
              });
              const clonedRequest = req.clone({headers : newHeaders});
              return next(clonedRequest);
            }),
            catchError(error2 => {
              return throwError(() => error2);
            })
          );
        } else {
          // Set it to false
          refreshTokenInProgress = false;
          authService.logOut();
          return throwError(() => error);
        }
      } else {
        return throwError(() => error);
      }
    })
  );
};
