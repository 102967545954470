import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { authInterceptor } from './core/interceptors/auth.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes), provideClientHydration(), provideHttpClient(withFetch(),withInterceptors([authInterceptor]))]  // Register the AuthInterceptor here)]
};

export const API_TOKEN_CEVEO = "62a66f3cead9bda47c67cb976c3da65d8d65b0d87df764224135204aea92e7c1f82137f84322d84cd6d50be7a7afa3807d1c022abe652822adc162322cd0fa3dc919dfb74405500b5f6523750e5c8572d31b6ead503a3bf9d03bca5b5ff5846a69e4d9a543c96827ed4a9973ec1b2319681379b3f3c8b93bf489f710da801635";
