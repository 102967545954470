import { Routes } from '@angular/router';
import { NotFoundPageComponent } from './features/commons/not-found-page/not-found-page.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./features/front-office/front-office-routing.module').then(
        (m) => m.FrontOfficeRoutingModule
      ),
  },
  {
    path: 'back-office',
    loadChildren: () =>
      import('./features/back-office/back-office-routing.module').then(
        (m) => m.BackOfficeRoutingModule
      ),
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];
